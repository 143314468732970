import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { CatalogsService } from '@shared/services/catalogs.service';
import { prepareCatalog } from '@shared/utils/catalogs';
import { FetchUpdates } from './catalogs.actions';
import {
  CatalogsStateModel,
  CommonCatalog,
  CustomCatalog,
  NsiChangeReason,
  NsiCountry,
  NsiCurrency,
  NsiCustoms,
  NsiCustomsPoint,
  NsiCustomsStorage,
  NsiDealCharacter,
  NsiDealFeature,
  NsiMeasureUnit,
  NsiPreference,
  NsiPresentAddType,
  NsiPresentType,
  NsiRegion,
  NsiTypeDocument,
} from '../../types/catalog';

type CatalogUpdateRequests = { [key: string]: Observable<any> };

const defaultState = () => {
  return {
    nsiCountry: null,
    nsiDealCharacterSD: null,
    nsiDealFeatureSD: null,
    nsiDealCharacterDT: null,
    nsiDealFeatureDT: null,
    nsiTypeDelivery: null,
    nsiCurrency: null,
    nsiCustoms: null,
    nsiMeasureUnit: null,
    nsiTypeDocument: null,
    nsiCancellationReason: null,
    nsiCorrectionReason: null,
    nsiRegion: null,
    nsiIdentityDocument: null,
    nsiCustomsCostMethod: null,
    nsiMovementFeature: null,
    nsiPackageType: null,
    nsiPaymentMode: null,
    nsiPaymentType: null,
    nsiPreference: null,
    nsiPresentType: null,
    nsiPresentAddType: null,
    nsiTransportType: null,
    nsiCustomsStorage: null,
    nsiCustomsStorageType: null,
    nsiCustomsProcedure: null,
    nsiDeliveryTerm: null,
    nsiCustomsPoint: null,
    nsiChangeReason: null,
  };
};
@State<CatalogsStateModel>({
  name: 'catalogs',
  defaults: defaultState(),
})
@Injectable()
export default class CatalogsState {
  constructor(private readonly catalogs: CatalogsService) {}

  @Action(FetchUpdates)
  fetchUpdates(ctx: StateContext<CatalogsStateModel>) {
    return this.catalogs.getLastUpdated().pipe(
      map(updates => {
        const providedCatalogs = defaultState();
        const catalogsState = ctx.getState();
        const requests: CatalogUpdateRequests = {};
        Object.keys(updates).forEach(key => {
          const catalogName = key as keyof CatalogsStateModel;
          if (catalogName in providedCatalogs) {
            if (
              catalogsState[catalogName] === null ||
              catalogsState[catalogName]?.updated !== updates[catalogName]
            ) {
              console.log(
                catalogsState[catalogName]?.updated,
                updates[catalogName],
              );
              console.log('FETCH CATALOG UPDATE >>>', catalogName);
              requests[catalogName] = this.catalogs.fetchCatalog(catalogName);
            }
          }
        });
        return requests;
      }),
      switchMap(requests => forkJoin(requests)),
      tap(catalogs => {
        ctx.patchState(catalogs);
      }),
    );
  }

  @Selector()
  static getNsiCustoms(state: CatalogsStateModel) {
    return prepareCatalog<NsiCustoms>(state.nsiCustoms);
  }

  @Selector()
  static getNsiCountries(state: CatalogsStateModel): CustomCatalog<NsiCountry> {
    return prepareCatalog<NsiCountry>(state.nsiCountry);
  }

  @Selector()
  static getNsiRegions(state: CatalogsStateModel) {
    return prepareCatalog<NsiRegion>(state.nsiRegion);
  }

  @Selector()
  static getNsiDealCharactersSD(state: CatalogsStateModel) {
    return prepareCatalog<NsiDealCharacter>(state.nsiDealCharacterSD);
  }

  @Selector()
  static getNsiDealCharactersDT(state: CatalogsStateModel) {
    return prepareCatalog<NsiDealCharacter>(state.nsiDealCharacterDT);
  }

  @Selector()
  static getNsiDealFeaturesSD(state: CatalogsStateModel) {
    return prepareCatalog<NsiDealFeature>(state.nsiDealFeatureSD);
  }

  @Selector()
  static getNsiDealFeaturesDT(state: CatalogsStateModel) {
    return prepareCatalog<NsiDealFeature>(state.nsiDealFeatureDT);
  }

  @Selector()
  static getNsiTypesOfDelivery(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiTypeDelivery);
  }

  @Selector()
  static getNsiTypesOfDocument(state: CatalogsStateModel) {
    return prepareCatalog<NsiTypeDocument>(state.nsiTypeDocument);
  }

  @Selector()
  static getNsiCurrencies(
    state: CatalogsStateModel,
  ): CustomCatalog<NsiCurrency> {
    return prepareCatalog<NsiCurrency>(state.nsiCurrency);
  }

  @Selector()
  static getNsiMeasureUnits(state: CatalogsStateModel) {
    return prepareCatalog<NsiMeasureUnit>(state.nsiMeasureUnit);
  }

  @Selector()
  static getNsiCorrectionReasons(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiCorrectionReason);
  }

  @Selector()
  static getNsiCancellationReasons(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiCancellationReason);
  }

  @Selector()
  static getNsiIdentityDocuments(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiIdentityDocument);
  }

  @Selector()
  static getNsiCustomsCostMethod(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiCustomsCostMethod);
  }

  @Selector()
  static getNsiMovementFeature(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiMovementFeature);
  }

  @Selector()
  static getNsiPackageType(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiPackageType);
  }

  @Selector()
  static getNsiPaymentMode(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiPaymentMode);
  }

  @Selector()
  static getNsiPaymentType(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiPaymentType);
  }

  @Selector()
  static getNsiPreference(state: CatalogsStateModel) {
    return prepareCatalog<NsiPreference>(state.nsiPreference);
  }

  @Selector()
  static getNsiPresentType(state: CatalogsStateModel) {
    return prepareCatalog<NsiPresentType>(state.nsiPresentType);
  }

  @Selector()
  static getNsiPresentAddType(state: CatalogsStateModel) {
    return prepareCatalog<NsiPresentAddType>(state.nsiPresentAddType);
  }

  @Selector()
  static getNsiTransportType(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiTransportType);
  }

  @Selector()
  static getNsiCustomsStorage(state: CatalogsStateModel) {
    return prepareCatalog<NsiCustomsStorage>(state.nsiCustomsStorage);
  }

  @Selector()
  static getNsiCustomsStorageType(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiCustomsStorageType);
  }

  @Selector()
  static getNsiCustomsProcedure(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiCustomsProcedure);
  }

  @Selector()
  static getNsiDeliveryTerm(state: CatalogsStateModel) {
    return prepareCatalog<CommonCatalog>(state.nsiDeliveryTerm);
  }

  @Selector()
  static getNsiCustomsPoint(state: CatalogsStateModel) {
    return prepareCatalog<NsiCustomsPoint>(state.nsiCustomsPoint);
  }

  @Selector()
  static getNsiChangeReason(state: CatalogsStateModel) {
    return prepareCatalog<NsiChangeReason>(state.nsiChangeReason);
  }
}
