import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, tap } from 'rxjs';
import {
  CatalogDetails,
  CommonCatalog,
  NsiCountry,
  NsiMeasureUnit,
  NsiPreference,
  NsiRegion,
  PreferenceByType,
  PreparedCatalog,
} from '../../types/catalog';
import { letterCodes, regionCodes } from '../constants/countries';

export function getCountryNameByCode(
  catalog: NsiCountry[],
  code: Nullable<string>,
) {
  return getFieldFromCatalogElement<NsiCountry>(
    catalog,
    code,
    'letterCode',
    'name',
  );
}

export function getCommonCatalogNameByCode(
  catalog: CommonCatalog[],
  code: Nullable<string>,
) {
  return getFieldFromCatalogElement<CommonCatalog>(
    catalog,
    code,
    'code',
    'name',
  );
}

export function getCountryDigitCodeByLetterCode(
  catalog: NsiCountry[],
  code: Nullable<string>,
) {
  return getFieldFromCatalogElement(catalog, code, 'letterCode', 'digitCode');
}

function getFieldFromCatalogElement<T extends Record<string, any>>(
  catalog: T[] = [],
  searchValue: Nullable<string>,
  searchKey: string,
  returnKey: string,
): Nullable<string> {
  if (Array.isArray(catalog) && catalog.length && searchValue) {
    const searchResult = catalog.find(catalogElement => {
      return searchKey in catalogElement
        ? catalogElement[searchKey as keyof T] === searchValue
        : false;
    });
    return searchResult && returnKey in searchResult
      ? searchResult[returnKey as keyof T]
      : '';
  }
  return '';
}

export function buildSearchField(item: Record<string, any>) {
  const { id, extId, dOn, dOff, ...otherKeys } = item;
  return Object.values(otherKeys).join('-').toLowerCase();
}

export function prepareCatalog<T>(
  details: CatalogDetails<T> | null,
): PreparedCatalog<T> {
  if (!details || !details.list.length) {
    return [];
  }
  return details.list.map(item => {
    return {
      ...item,
      search: buildSearchField(item as Record<string, any>),
    };
  });
}

export function getRegionsByCountryLetter<T extends NsiRegion>({
  countries,
  letterCode,
  regions,
}: {
  countries: NsiCountry[];
  regions: T[];
  letterCode: Nullable<string>;
}) {
  const { RU, KZ, UA } = letterCodes;
  const digit = getCountryDigitCodeByLetterCode(countries, letterCode);
  if (!digit) {
    return regions;
  }
  if (![RU, KZ, UA].includes(letterCode ?? '')) {
    return regions
      .filter(region => region.code === regionCodes.OTHER)
      .slice(0, 1);
  }
  return regions.filter(region => region.countryDigit === digit);
}

export function getMeasureUnitsLetterByDigitCode(
  code: Nullable<string>,
  measures: NsiMeasureUnit[],
) {
  return code === null
    ? null
    : measures.find(m => m.code === code)?.shortDescription ?? null;
}

export function fillCountryName<T>(
  formGroup: FormGroup<{ [key in keyof T]: FormControl }>,
  countries: NsiCountry[],
  from: keyof T,
  to: keyof T,
): Observable<any> {
  const controls = formGroup.controls;
  return controls[from].valueChanges.pipe(
    tap((letterCode: Nullable<string>) => {
      if (controls[from].dirty) {
        if (letterCode) {
          const name = getCountryNameByCode(countries, letterCode);
          controls[to].setValue(name);
        } else {
          controls[to].setValue(null);
        }
      }
    }),
  );
}

export function groupPreferences(stream$: Observable<NsiPreference[]>) {
  return stream$.pipe(
    map(preferences => {
      const separatedByType: PreferenceByType = {
        preference1: [],
        preference2: [],
        preference3: [],
        preference4: [],
      };
      preferences.forEach(preference => {
        const key = `preference${preference.type}` as keyof PreferenceByType;
        separatedByType[key].push({ ...preference, search: preference.code });
      });
      return separatedByType;
    }),
  );
}
